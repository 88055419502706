// @ts-ignore
import Raphael from 'raphael';
import FlowDesignVar from "@/views/sysviews/flow/define/ts/flowDesign/flowDesignVar";
import FlowUtil from "@/views/sysviews/flow/define/ts/flowDesign/flowUtil";
import ConnectUtil from "@/views/sysviews/flow/define/ts/flowDesign/connectUtil";
import RaphaelDragUtil from "@/views/sysviews/flow/define/ts/flowDesign/raphaelDragUtil";
//连线两个节点以及创建连线上的文本options={obj1、obj2、[nodeProperty]}；其中nodeProperty(可选)是传给文本节点的属性设置，比如转移条件
Raphael.fn.drawConnect = function (options:any) {
    if(!options)return;
    let point = RaphaelDragUtil.getStartEnd(options.obj1, options.obj2) as any;if(!point)return;//obj1、obj2的坐标
    let type:number=0;//画的连线带箭头
    if(options.obj2.nodeSelfType==FlowDesignVar.connectPoint)type=1;//如果结束节点是一个点，则画的连线不带箭头
    let path = RaphaelDragUtil.getArr(point.start.x, point.start.y, point.end.x, point.end.y, 8,type);
    ConnectUtil.createConnectText({obj1:options.obj1,path:path,obj2:options.obj2,type:type,nodeProperty:options.nodeProperty});//创建连线上面的文字节点
    if (!options.arrPath)options.arrPath = this.path(path);
    options.arrPath.attr({path:path});
    // options.arrPath.attr({ path: path,stroke: "red","stroke-width": 4});//改变连线的大小和颜色
    return options;
};
//初始化右键菜单选项
FlowDesignVar.initContextMenu();

//右键菜单事件,放到flowDesign里面的话，调用不到，估计flowDesign被编译之后会改变作用域吧
// @ts-ignore
window.menuHandle= function menuHandle(menuItemId:string) {
    FlowUtil.closeContextMenu();//关闭右键菜单，然后执行点击的右键事件
    let node;
    switch (menuItemId) {
        case "addProcessProperty":// 设置流程属性
            node=FlowDesignVar.nodeMenu.node;//不需要找到父节点，因为这是body的右键事件，创建右键菜单的时候，传入的node实际上是画布
            break;
        case "saveDiagram":// 保存
            FlowDesignVar.inst.saveHandler();
            break;
        case "editNode":// 编辑步骤
            node=FlowDesignVar.nodeMenu.node;//节点添加右键事件的时候，在右键菜单那里会记录是哪个dom节点，这个时候dom节点可能是流程节点的文本节点，
            if(node.parentNode)node=node.parentNode;//由于nodeProperty是设置到image节点上的，所以在打开属性弹出框之前，要为openNodeDialog传入image节点才行，这样才能找到nodeProperty
            FlowDesignVar.inst.openNodeDialog(node);
            break;
        case "deletePoint":// 删除点
        case "deleteNode":// 删除具体的流程步骤节点
            if(menuItemId=='deletePoint') node=FlowDesignVar.pointMenu.node;
            else node=FlowDesignVar.nodeMenu.node;
            if(node.parentNode)node=node.parentNode;
            if(node.nodeSelfType=="must_task" || node.nodeSelfType=="start" ||node.nodeSelfType=="end"){
                FlowDesignVar.inst.$message({type:'warning',message:'不允许删除开始、结束和固定步骤节点!'})
                return;
            }else if(node.nodeSelfType==FlowDesignVar.connectPoint){//如果是点击删除的折线点
                ConnectUtil.deleteBrokenLine(node);//折线上可能没有文字，所以点击折线点也可以删除连线和折线点
                FlowUtil.deleteNode(node);
                FlowUtil.reDraw();//还tmd必须重画，否则肉眼看到的还没有删除掉，只是把连线对象从我们数组中干掉了
            }else{
                if(ConnectUtil.checkNodeExistConnect(node)){
                    FlowDesignVar.inst.$message({type:'warning',message:'请先删除连线，再来删除节点！'})
                    return;
                }
                FlowUtil.deleteNode(node);
            }

            break;
        case "editConnect":// 设置转移
            node=FlowDesignVar.connectMenu.node;
            FlowDesignVar.inst.openConditionNodeDialog(node);
            break;
        case "changeDirection"://改变连线提交方向(连接线上的文字节点右键菜单)
            node=FlowDesignVar.connectMenu.node;
            FlowUtil.changeDirection(node);
            break;
        case "deleteConnect":// 删除连线
            node=FlowDesignVar.connectMenu.node;
            ConnectUtil.deleteConnect(node);
            FlowUtil.reDraw();//还tmd必须重画，否则肉眼看到的还没有删除掉，只是把连线对象从我们数组中干掉了
            break;
    }
}

let flowDesign={
    //初始化画布，根据传入的参数，画流程图
    initDesign(options:any){
        FlowDesignVar.engine=options.engine;//卡片引擎
        FlowDesignVar.inst=options.inst;//DefineCard模块对象
        //保存之后，重新加载画布，会画出两个svg区域，很奇怪，所以在重绘之前先删除canvasArea下的所有子元素
        let canvasArea = document.getElementById("canvasArea") as HTMLElement;
        let childNodes = canvasArea.childNodes;
        for(let i = 0; i < childNodes.length; i++) canvasArea.removeChild(childNodes[i]);
        //创建绘图对象
        FlowDesignVar.r = Raphael("canvasArea", (document.getElementById("canvasArea") as HTMLElement).offsetWidth,(document.getElementById("canvasArea") as HTMLElement).offsetHeight-10);
        //清空画布和相应数组
        FlowDesignVar.r.clear(); FlowDesignVar.connectNodes = [];FlowDesignVar.canvas.connectTextArray=[];FlowDesignVar.canvas.nodeArray=[];
        FlowDesignVar.connectProperties=options.data.flowBean.listConnectPropertyBeans;//连线节点上的属性
        FlowUtil.setConnStatus(false);//把连接状态标识重置
        FlowUtil.initNodes(options.data.flowBean.listNodeBeans);//在画布上根据后台返回的节点集合画出各个节点，而且会往FlowDesignVar.connectIds存入节点的连接信息
        ConnectUtil.drawNodeConnectByConnectIds(FlowDesignVar.connectIds); //根据节点之间的连线信息，画出节点之间的连线
        FlowUtil.bodyBindEvent("canvasArea"); //给画布绑定右键事件
    },
    //设置连线状态
    setConnStatus(status:boolean){
        FlowUtil.setConnStatus(status);
    },
    //关闭右键菜单
    closeContextMenu(){
        FlowUtil.closeContextMenu();
    },
    //在画布上面画一个节点（自定义任务节点）
    async drawUserTaskNode(options:any){
        let selfId=await FlowDesignVar.inst.utils.Api.getNewId();
        let childSelfId=await FlowDesignVar.inst.utils.Api.getNewId();
        FlowUtil.drawImgNode_textNode({
            img:FlowDesignVar.inst.flowImages.userTaskImg,
            x:options.x-80,//这样创建的图片刚好在鼠标点击的中间
            y:options.y-170,
            text:options.text,//节点显示的文本（孩子节点text显示文本）
            selfId:selfId,//节点id
            childSelfId:childSelfId,//该节点的孩子节点（text）id
            nodeSelfType:'userTask',
            nodeProperty:'',//节点属性
        });
    },
    //检查节点是否有连线（保存的时候，如果节点没有用连线连起来就不允许保存）
    checkCanSave(node:any){
        let result=false,beganFlag=false,endFlag=false;
        let len=FlowDesignVar.connectNodes.length;
        for(let i=0;i<len;i++){
            let obj1=FlowDesignVar.connectNodes[i].obj1;
            let obj2=FlowDesignVar.connectNodes[i].obj2;
            if(obj1.selfId==node.selfId)beganFlag=true;
            if(obj2.selfId==node.selfId)endFlag=true;
            //如果是开始节点或者结束节点，只需要有连线出发或者连接结束就合法了,其它节点必须有连线发出和连线介绍
            if(node.nodeSelfType=='start' && beganFlag)result=true;
            if(node.nodeSelfType=='end' && endFlag)result=true;
            if(beganFlag && endFlag)result=true;
            if(result)break;
        }
        return result;
    },
    //获取画布保存信息
    getSaveData(){
        let node:any=[],connText:any=[];//节点和节点上的文字节点、连接两个节点之间的连线以及连线上的文字节点
        try {
            FlowDesignVar.r.forEach((nodeItem:any)=>{
                if(nodeItem.childNode || nodeItem.nodeSelfType==FlowDesignVar.connectPoint){//保存的时候只处理节点，不处理连线，因为节点上有连线所需信息
                    if(!this.checkCanSave(nodeItem)){
                        if(nodeItem.childNode)alert("请将节点 ("+nodeItem.childNode.attrs.text+") 的连线补充完整才能保存");
                        else alert("请将节点的连线补充完整才能保存");
                        throw new Error('请补充完节点的连线才能保存');//forEach只能通过抛异常结束，用return结束不了
                    }
                    node.push({
                        selfId:nodeItem.selfId,//节点id
                        x:nodeItem.attrs.x,//节点x坐标
                        y:nodeItem.attrs.y,//节点y坐标
                        childSelfId:nodeItem.childNode?nodeItem.childNode.selfId:'',//节点上面的文字节点id
                        text:nodeItem.childNode?nodeItem.childNode.attrs.text:'',//节点上文字节点的显示文本
                        nodeSelfType:nodeItem.nodeSelfType,//节点类别：start、must_task、end、userTask
                        nodeProperty:nodeItem.nodeProperty?nodeItem.nodeProperty:''//节点设置的属性
                    });
                }
            },this);
        }catch (e) {
            return undefined;
        }
        FlowDesignVar.canvas.connectTextArray.forEach((item:any)=>{
            connText.push({
                selfId:item.selfId,//连线上文字text对象的id，其实就是两个节点的id结合，比如：start####makeBill
                x:item.attrs.x,//连线上文字text对象的x坐标
                y:item.attrs.y,//连线上文字text对象的y坐标
                text:item.attrs.text,//连线上文字text对象的显示文本
                nodeProperty:item.nodeProperty,//连线的额外属性，比如转移条件等等
            });
        })
        //保存数据：节点集合、节点连线集合。到后台再根据这两个数组组装成为xml保存到数据库中
        let saveData={node:node,connText:connText};
        return saveData;
    }
};
export default flowDesign;



